<template>
  <div>
    <p style="font-size: 12px; color: #999; margin-bottom: 7px">
      <slot name="prompt">{{ text }} </slot>
    </p>

    <el-upload
      ref="fileList"
      :action="uploadUrl"
      :auto-upload="true"
      :before-upload="beforeUpload"
      :class="hideUploadClass"
      :data="uploadData"
      :file-list="fileList"
      :limit="uploadNumber"
      :on-change="onChange"
      :on-exceed="onExceed"
      :on-remove="onRemove"
      :on-success="onSuccess"
      list-type="picture-card"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img :src="file.url" alt="" class="el-upload-list__item-thumbnail" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img :src="dialogImageUrl" alt="" width="100%" />
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  components: {},
  props: {
    uploadPath: {
      default: "slideShow",
      type: String,
    },
    uploadNumber: {
      default: 1,
      type: Number,
    },
    text: {
      default: "980*500像素或16:9，支持PNG、JPG、GIF格式，小于5M",
      type: String,
    },
  },
  name: "cover",
  data() {
    return {
      uploadUrl: "",
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      hideUploadClass: "",
      uploadData: {},
      fileName: "",
      path: "",
    };
  },
  methods: {
    ...mapActions("common", ["getOssInfo"]),
    handleRemove() {
      // this.$refs.refName.handleRemove(file)
      this.fileList = [];
      this.hideUploadClass = "";
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    onChange(file, fileList) {
      let fileName = new Date().getTime() + "." + file.name.split(".").pop();

      if (fileList.length >= this.uploadNumber) {
        this.hideUploadClass = "hide";
      } else {
        this.hideUploadClass = "";
      }
      this.fileName = fileName;
    },
    onRemove(file, fileList) {
      console.log(file);
      console.log(fileList);
    },
    onExceed(file) {
      this.$message.error("最多只能选取" + this.uploadNumber + "张图片");
    },
    async beforeUpload() {
      let _this = this;
      await this.getOssInfo(this.uploadPath).then((res) => {
        this.uploadUrl = res.data.host;
        _this.path = res.data.dir + "/" + _this.fileName;
        let data = {
          key: _this.path,
          policy: res.data.policy,
          OSSAccessKeyId: res.data.accessid,
          signature: res.data.signature,
          callback: res.data.callback,
        };
        _this.uploadData = data;
      });
    },
    onSuccess() {
      this.$notify({
        title: "上传成功",
        type: "success",
        message: "图片已成功上传",
      });
      console.log("上传成功");
      this.$emit("success", this.path);
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.$on("reset", function () {
        this.uploadUrl = "";
        this.fileList = [];
        this.dialogImageUrl = "";
        this.dialogVisible = false;
        this.disabled = false;
        this.hideUploadClass = "";
        this.uploadData = {};
        this.fileName = "";
        this.path = "";
      });
    });
  },
};
</script>

<style>
.el-upload--picture-card {
  width: 200px;
  height: 112px;
  background-color: #fafafa;
  border: 1px solid #ebebeb;
  cursor: pointer;
  line-height: 112px;
  text-align: center;
  font-size: 26px;
  font-weight: 400;
  color: #e6e6e6;
}

.el-upload-list--picture-card .el-upload-list__item {
  width: 200px;
  height: 112px;
  margin: 0 8px 0 0;
}

.el-upload-list--picture-card .el-upload-list__item div {
  width: 100%;
  height: 100%;
}

.hide .el-upload--picture-card {
  display: none;
}
</style>