<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >专栏</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/specialColumn' }">专栏设置</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form" label-width="120px" size="mini" :rules="rules" >

                <el-form-item label="封面：" label-position="left"  prop="pic" >
                    <cover @success="uploadSuccess"></cover>
                </el-form-item>

                <el-form-item label="名称：" label-position="left"  prop="name"  >
                    <el-input style="width: 200px"  v-model="form.name" placeholder="请输入名称"></el-input>
                </el-form-item>

                <el-form-item label="书本数量：" label-position="left"  prop="count"  >
                    <el-input style="width: 200px"  v-model="form.count" placeholder="请输入书本数量"></el-input>
                </el-form-item>

                <el-form-item label="简介：" label-position="left"  prop="intro"  >
                    <el-input type="textarea" resize="none" rows="6"  style="width: 1100px"  v-model="form.intro" placeholder=""></el-input>
                </el-form-item>

                <el-form-item label="排序：" label-position="left"  >
                    <el-input style="width: 200px"  v-model.number="form.sort" placeholder="请输入价格"></el-input>
                </el-form-item>

                <el-form-item label="首页推荐：" label-position="left"  >
                    <el-switch v-model="form.recommend"  :active-value="1" :inactive-value="0">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{form.recommend === 1 ? '推荐' : '不推荐'}}</span>
                </el-form-item>

                <el-form-item label="状态：" label-position="left"  >
                    <el-switch v-model="form.status" :active-value="1" :inactive-value="0">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{form.status === 1 ? '上架' : '下架'}}</span>
                </el-form-item>


                <el-form-item>
                    <el-button size="small"  type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                    <router-link to="/specialColumn" class="router-link" ><el-button style="margin-left: 10px" size="small" >取消</el-button></router-link>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import Cover from "../../../components/cover";
    export default {
        name: "template-add",
        components: { Cover},
        data() {
            return {
                statusTxt:'上架',
                loading:false,
                form: {
                    name:'',
                    status:1,
                    pic:'',
                    intro:'',
                    sort:0,
                    count:0,
                    recommend:0
                },
                options: [
                    {
                        value: 0,
                        label: '展示图片'
                    },
                    {
                        value: 1,
                        label: '跳转文章'
                    },
                    {
                        value: 2,
                        label: '跳转下载'
                    },
                    {
                        value: 3,
                        label: '跳转外部链接'
                    }
                ],
                rules: {
                    name: [
                        { required: true, message: '请输入名称', trigger: 'blur' },
                    ],
                    count: [
                        { required: true, message: '请输入书本数量', trigger: 'blur' },
                    ],
                    pic: [
                        {  required: true, message: '请选择图片封面', trigger: 'change' }
                    ],
                    author: [
                        { required: true, message: '请输入作者', trigger: 'blur' }
                    ],
                    intro: [
                        {  required: true, message: '请输入简介', trigger: 'blur' },
                    ],
                    content: [
                        { required: true, message: '请输入内容', trigger: 'blur' },
                    ],
                    sort: [
                        { required: true, message: '请输入价格', trigger: 'blur' },
                    ],
                },
            }
        },
        methods: {
            ...mapActions('specialColumn',['addSpecialColumn']),
            uploadSuccess(path){
                this.form.pic = path
            },
            async onSubmit() {

                if(!this.validateForm('form'))
                {
                    this.$message.error('请填写必填选项！')
                    return false
                }


                let _this = this
                this.loading = true
                console.log(this.form)

                await this.addSpecialColumn(this.form).then(res => {
                    if(res.ret == 0){
                        this.$message.success('新增成功！')
                        this.$router.push('/specialColumn')
                    }else{
                        this.$message.error('新增失败！')
                    }
                })

                this.loading = false
            },
            setStatus(status){
                console.log(status)
                if(this.form.status){
                    this.statusTxt = '上架'
                }else{
                    this.statusTxt = '下架'
                }
                this.form.status = status
            },
            onChange(content){
                console.log(content)
                this.form.content = content
            },
            selectType(val){
                this.form.type = val
            },
            onSelect(audio){
                this.form.audioId = audio.id
                console.log(audio)
            },
            validateForm(formName){
                let validate = false
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        validate = true
                        return true
                    } else {
                        validate = false
                        return false;
                    }
                });
                return validate
            },
        }
    }
</script>

<style >

</style>
